<template>
  <div class="update-store-container" v-loading="boxLoading">
    <el-form class="form-container" ref="form" :model="form" :rules="rules" label-width="160px">
      <el-row :gutter="24">
        <el-col :span="12" style="border-right: 1px solid #eee">
          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">基础信息</span>
          </div>
          <el-form-item label="供应商名称:" prop="storeName">
            <el-input v-model="form.storeName" placeholder="请输入供应商名称" maxlength="30" clearable></el-input>
          </el-form-item>
          <el-form-item label="供应商类型:" prop="storeType">
            <el-select v-model="form.storeType" placeholder="请选择供应商类型" filterable clearable>
              <el-option v-for="(item, i) in storeTypeList" :key="i" :label="item.dictionaryName" :value="item.dictionaryValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商性质:" prop="storeAttr">
            <el-select v-model="form.storeAttr" placeholder="请选择">
              <el-option label="企业" :value="1"></el-option>
              <el-option label="个人" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属区域:" prop="storeRegion">
            <el-row :gutter="0">
              <el-col :span="8">
                <el-select v-model="form.provinceId" placeholder="省" @change="mirProvince" filterable>
                  <el-option v-for="(item, i) in provinceList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select style="margin: 0 8px" v-model="form.cityId" placeholder="市" @change="mirCity" filterable>
                  <el-option v-for="(item, i) in cityList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="form.districtId" placeholder="区" filterable>
                  <el-option v-for="(item, i) in areaList" :key="i" :label="item.districtName" :value="item.districtId"></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="封面图:">
            <uploadImg size="2.5" :imageUrl.sync="form.coverPictureUrl" text="封面图" />
            <!-- <UploadFile title="封面图" uploadFileType="img" prompt="仅支持单图上传" :uploadMultiple="1" :fileList.sync="form.coverPictureUrl" /> -->
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model="form.address" type="textarea" :rows="2" placeholder="请输入详细位置及门牌号"></el-input>
          </el-form-item>
          <el-form-item label="供应商坐标:" prop="coordinateE">
            <div class="select-cordinate" :class="!!form.lng ? 'select-checked' : 'select-cordinate-placeholder'" @click="handleSelectCoordinate()">
              {{ !!form.lng ? form.lat + ',' + form.lng : '请选择坐标' }}
            </div>
          </el-form-item>
          <el-form-item label="营业日:" prop="businessDay">
            <el-checkbox-group v-model="form.businessDay">
              <el-checkbox v-for="item in storeBusinessDayList" :label="item.dictionaryValue" :key="item.dictionaryId">{{ item.dictionaryName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="营业时间:" prop="storeBusinessTime">
            <el-time-picker
              is-range
              v-model="form.storeBusinessTime"
              value-format="HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              clearable
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="午休时间:" prop="breakTime">
            <el-time-picker
              is-range
              v-model="form.breakTime"
              value-format="HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              clearable
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contactNumber">
            <el-input v-model="form.contactNumber" placeholder="请输入联系电话" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">证件信息</span>
          </div>
          <div v-if="form.storeAttr == 2">
            <el-form-item label="身份证正面照片:">
              <uploadImg :imageUrl.sync="form.idNumFrontImg" text="人像面的证件照片" />
            </el-form-item>
            <el-form-item label="身份证反面照片:">
              <uploadImg :imageUrl.sync="form.idNumBackImg" text="国徽面的证件照片" />
            </el-form-item>
          </div>

          <div v-else>
            <el-form-item label="营业执照照片:">
              <uploadImg size="2.5" :imageUrl.sync="form.businessLicenseImg" text="营业执照照片" />
            </el-form-item>
            <el-form-item label="法人身份证正面照片:">
              <uploadImg :imageUrl.sync="form.idNumFrontImg" text="人像面的证件照片" />
            </el-form-item>
            <el-form-item label="法人身份证反面照片:">
              <uploadImg :imageUrl.sync="form.idNumBackImg" text="国徽面的证件照片" />
            </el-form-item>
          </div>
          <!-- <el-form-item label="收款人姓名:" prop="cardName">
            <el-input v-model="form.cardName" placeholder="请输入银行卡对应的账户名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="提现银行:" prop="bankId">
            <el-select v-model="form.bankId" placeholder="请选择提现银行">
              <el-option v-for="(item, i) in backList" :key="i" :label="item.dictionaryName" :value="item.dictionaryValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款银行卡号:" prop="cardNumber">
            <el-input v-model="form.cardNumber" placeholder="请输入提现打款银行卡号" clearable></el-input>
          </el-form-item> -->
          <div class="title" style="margin-top: 35px">
            <span class="title-mark"></span>
            <span class="title-text">管理员账号设置</span>
          </div>
          <el-form-item label="登录手机号:" prop="administratorPhoneNumber">
            <el-input v-model="form.administratorPhoneNumber" placeholder="请输入登录手机号" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="登录密码:" prop="passWord">
            <el-input v-model="form.passWord" placeholder="请输入登录密码" show-password clearable></el-input>
          </el-form-item>
          <el-form-item label="确认登录密码:" prop="assignPassword">
            <el-input v-model="form.assignPassword" placeholder="请再次输入登录密码" show-password clearable></el-input>
          </el-form-item> -->
          <el-form-item label="后台权限:" prop="isDisable">
            <el-radio-group v-model="form.isDisable">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
            <span class="prompt">（禁用后，供应商账号不能登录且服务不能预约本供应商）</span>
          </el-form-item>

          <div class="title" style="margin-top: 35px">
            <span class="title-mark"></span>
            <span class="title-text">ERP设置</span>
            <span class="title-subtitle">(涉及到棚位的供应商必须设置)</span>
          </div>
          <el-form-item label="ERP部门ID:" prop="erpDeptId">
            <!-- <el-input v-model="form.erpDeptId" placeholder="请输入ERP部门ID" clearable></el-input> -->
            <el-autocomplete
              v-model="erpDept"
              value-key="dptno"
              label="dptname"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入ERP部门编号或ERP部门名称"
              style="width: 100%"
              popper-class="my-autocomplete"
            >
              <template slot-scope="{ item }">
                <div @click="erpDeptIdChange(item)">
                  <div class="prdtname">
                    <span>{{ item.dptname + ' - ' }}</span>
                    <span>{{ item.dptno }}</span>
                  </div>
                  <div>
                    <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
                    <span class="brdtype">{{ item.brandno }}</span>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>

          <el-form-item label="ERP门店管理员ID:" prop="erpStoreStaffId">
            <!-- <el-input v-model="form.erpStoreStaffId" placeholder="请输入ERP门店员工ID" clearable></el-input> -->
            <el-autocomplete
              v-model="erpStoreStaff"
              value-key="empno"
              label="empname"
              :fetch-suggestions="querySearchStaffAsync"
              placeholder="请输入ERP门店管理员名称"
              style="width: 100%"
              popper-class="my-autocomplete"
            >
              <template slot-scope="{ item }">
                <div @click="erpStoreStaffChange(item)">
                  <div class="prdtname">
                    <span>{{ item.empname + ' - ' }}</span>
                    <span>{{ item.empno }}</span>
                  </div>
                  <div>
                    <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
                    <span class="brdtype">{{ item.postname }}</span>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="关联默认ERP棚位:" prop="erpFixedShedId">
            <!-- <el-input v-model="form.erpStoreStaffId" placeholder="请输入ERP门店员工ID" clearable></el-input> -->
            <el-autocomplete
              v-model="erpFixedShed"
              value-key="phtstno"
              label="phtstname"
              :fetch-suggestions="querySearchShed"
              placeholder="请关联ERP棚位"
              style="width: 100%"
              popper-class="my-autocomplete"
            >
              <template slot-scope="{ item }">
                <div class="prdtname" @click="erpFixedShedChange(item)">
                  <span>{{ item.phtstname + ' - ' }}</span>
                  <span>{{ item.phtstno }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item class="form-submit-container">
            <el-button class="form-submit-btn" type="primary" :loading="submitLoadingStatus" @click="submitForm()">提交</el-button>
            <el-button class="form-submit-btn" @click="handleComeBack()">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <AddMapPopups :popupsTitle="popupsTitle" :showState="isMap" :longitude.sync="form.lng" :latitude.sync="form.lat" v-on:hidePopups="isMap = false" />
  </div>
</template>

<script>
import AddMapPopups from './components/map';
import { getDictionaryList } from '@/api/common';
import { getCitys, getStoreDetail, updateStore } from '@/api/store';
import { searchdpt, searchemp, searchSeat } from '@/api/shed-space';
import uploadImg from '@/components/uploadImg2';

export default {
  components: {
    AddMapPopups,
    uploadImg,
  },
  data() {
    return {
      id: Number(this.$route.query.id),
      boxLoading: false,
      submitLoadingStatus: false,
      storeTypeList: [],
      backList: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      storeBusinessDayList: [],
      form: {
        storeAttr: '',
        storeId: undefined,
        //基础信息
        storeName: undefined,
        storeType: undefined,
        provinceId: undefined,
        cityId: undefined,
        districtId: undefined,
        coverPictureUrl: '',
        lng: undefined,
        lat: undefined,
        address: undefined,
        businessDay: [],
        storeBusinessTime: undefined,
        contactNumber: undefined,
        breakTime: ['12:00:00', '13:00:00'],
        //提现设置
        cardName: undefined,
        bankId: undefined,
        cardNumber: undefined,

        //管理员账号设置
        administratorPhoneNumber: undefined,
        // passWord: undefined,
        // assignPassword: undefined,
        isDisable: undefined,
        idNumFrontImg: '',
        idNumBackImg: '',
        businessLicenseImg: '',
        payeeCardFrontImg: '',
        payeeCardBackImg: '',
        erpDeptId: '',
        erpStoreStaffId: '',
        erpFixedShedId: '',
      },
      rules: {
        storeName: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        storeType: [{ required: true, message: '请选择供应商类型', trigger: 'change' }],
        storeRegion: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!this.form.provinceId) {
                callback(new Error('请选择所属区域'));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
        lng: [{ required: true, message: '请选择供应商坐标', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        businessDay: [{ type: 'array', required: true, message: '请选择营业日', trigger: 'change' }],
        storeBusinessTime: [{ required: true, message: '请选择营业时间', trigger: 'change' }],
        contactNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        cardName: [{ required: true, message: '请输入收款人姓名', trigger: 'blur' }],
        bankId: [{ required: true, message: '请选择提现银行', trigger: 'change' }],
        cardNumber: [{ required: true, message: '请输入收款银行卡号', trigger: 'blur' }],
        administratorPhoneNumber: [{ required: true, message: '请输入登录手机号', trigger: 'blur' }],
        loginPassword: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        // passWord: [
        //   {
        //     required: false,
        //     validator: (rule, value, callback) => {
        //       if (!!this.form.assignPassword && this.form.assignPassword !== value) {
        //         callback(new Error('两次密码不一致'));
        //       } else {
        //         callback();
        //         this.$refs.form.clearValidate('assignPassword');
        //       }
        //     },
        //     trigger: 'blur'
        //   }
        // ],
        // assignPassword: [
        //   {
        //     required: false,
        //     validator: (rule, value, callback) => {
        //       if (!!this.form.passWord && this.form.passWord !== value) {
        //         callback(new Error('两次密码不一致'));
        //       } else {
        //         callback();
        //         this.$refs.form.clearValidate('passWord');
        //       }
        //     },
        //     trigger: 'blur'
        //   }
        // ],
        isDisable: [{ required: true, message: '请选择后台权限', trigger: 'change' }],
        idNumFrontImg: [{ required: true, message: '请选择身份证正面照片', trigger: 'change' }],
        idNumBackImg: [{ required: true, message: '请选择身份证反面照片', trigger: 'change' }],
        businessLicenseImg: [{ required: true, message: '请选择营业执照照片', trigger: 'change' }],
      },
      popupsTitle: '地图',
      isMap: false,
      currentItem: {},
      erpStoreStaff: '',
      erpDept: '',
      erpFixedShed: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    erpStoreStaffChange(e) {
      this.form.erpStoreStaffId = e.empno;
    },
    erpDeptIdChange(e) {
      this.form.erpDeptId = e.dptno;
    },
    erpFixedShedChange(e) {
      this.form.erpFixedShedId = e.phtstno;
    },
    querySearchAsync(queryString, cb) {
      console.log(queryString);
      this.form.erpDeptId = '';
      searchdpt({
        data: {
          searchKey: queryString,
        },
      }).then((res) => {
        console.log(res.data);
        cb(res.data);
      });
    },
    querySearchStaffAsync(queryString, cb) {
      console.log(queryString);
      this.form.erpStoreStaffId = '';
      searchemp({
        data: {
          keyname: queryString,
        },
      }).then((res) => {
        console.log(res.data);
        cb(res.data);
      });
    },
    querySearchShed(queryString, cb) {
      if (!this.form.erpDeptId) return this.$message.error('请先选择部门！');
      this.form.erpFixedShedId = '';
      searchSeat({
        data: {
          dptno: this.form.erpDeptId,
          keyname: queryString,
        },
      }).then((res) => {
        cb(res.data);
      });
    },
    async getData() {
      try {
        this.boxLoading = true;

        this.storeTypeList = (await getDictionaryList({ data: { key: 'store_type' } })).data.childs || [];
        // console.log(this.storeTypeList);

        this.backList = (await getDictionaryList({ data: { key: 'store_tx_bank' } })).data.childs || [];

        this.storeBusinessDayList = (await getDictionaryList({ data: { key: 'store_workday' } })).data.childs || [];

        this.provinceList = (await getCitys({ data: { districtType: 1 } })).data || [];

        let responseDetail = (await getStoreDetail({ data: { storeId: [this.id] } })).data || [];

        Object.keys(this.form).forEach(async (key) => {
          if (key === 'storeType' || key === 'provinceId' || key === 'bankId') {
            this.form[key] = String(responseDetail[0][key]);
          } else if (key === 'cityId') {
            this.cityList = (await getCitys({ data: { districtId: parseInt(responseDetail[0].provinceId), districtType: 2, status: 1 } })).data || [];
            this.form[key] = String(responseDetail[0][key]);
          } else if (key === 'districtId') {
            this.areaList = (await getCitys({ data: { districtId: parseInt(responseDetail[0].cityId), districtType: 3 } })).data || [];
            this.form[key] = String(responseDetail[0][key]);
          } else if (key === 'coverPictureUrl') {
            // if (responseDetail[0].coverPictureUrl) {
            //   this.form[key] = [{ http: responseDetail[0].coverPictureUrl }];
            // } else {
            //   this.form[key] = [];
            // }
            this.form[key] = responseDetail[0].coverPictureUrl;
          } else if (key === 'lng' || key === 'lat') {
            this.form[key] = Number(responseDetail[0][key]);
          } else if (key === 'businessDay') {
            this.form[key] = JSON.parse(responseDetail[0][key]);
          } else if (key === 'storeBusinessTime') {
            this.form.storeBusinessTime = [];
          } else {
            this.form[key] = responseDetail[0][key];
          }
        });
        console.log(responseDetail);
        this.form.storeAttr = responseDetail[0].storeAttr;
        this.form.idNumBackImg = responseDetail[0].idNumBackImg;
        this.form.idNumFrontImg = responseDetail[0].idNumFrontImg;
        this.form.businessLicenseImg = responseDetail[0].businessLicenseImg;
        this.form.erpDeptId = responseDetail[0].erpDeptId;
        this.form.erpStoreStaffId = responseDetail[0].erpStoreStaffId;
        this.form.erpFixedShedId = responseDetail[0].erpFixedShedId;
        this.erpDept = responseDetail[0].erpDeptId;
        this.erpStoreStaff = responseDetail[0].erpStoreStaffId;
        this.erpFixedShed = responseDetail[0].erpFixedShedId;
        // console.log(JSON.parse(JSON.stringify(this.form)));

        this.form.storeBusinessTime[0] = responseDetail[0].businessStartTime;
        this.form.storeBusinessTime[1] = responseDetail[0].businessEndTime;
        console.log(responseDetail[0]);
        if (responseDetail[0].breakStartTime && responseDetail[0].breakEndTime) {
          this.form.breakTime = [responseDetail[0].breakStartTime, responseDetail[0].breakEndTime];
        } else {
          this.form.breakTime = null;
        }

        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    async mirProvince(value) {
      this.form.cityId = undefined;
      this.form.districtId = undefined;
      this.cityList = (await getCitys({ data: { districtId: value, districtType: 2, status: 1 } })).data || [];
    },
    async mirCity(value) {
      this.form.districtId = undefined;
      this.areaList = (await getCitys({ data: { districtId: value, districtType: 3 } })).data || [];
    },
    handleSelectCoordinate() {
      this.isMap = true;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.erpDept && !this.form.erpDeptId) return this.$message.error('请从搜索出来的ERP部门中选择一个~');
          if (this.erpStoreStaff && !this.form.erpStoreStaffId) return this.$message.error('请从搜索出来的ERP员工中选择一位~');
          if (this.erpFixedShed && !this.form.erpFixedShedId) return this.$message.error('请从搜索出来的ERP棚位中选择一个~');
          this.submitLoadingStatus = true;
          let formData = JSON.parse(JSON.stringify(this.form));
          formData.storeId = parseInt(formData.storeId);
          formData.storeType = parseInt(formData.storeType);
          // formData.coverPictureUrl = formData.coverPictureUrl;
          formData.businessStartTime = formData.storeBusinessTime[0];
          formData.businessEndTime = formData.storeBusinessTime[1];

          formData.provinceId = parseInt(formData.provinceId);
          formData.cityId = parseInt(formData.cityId);
          formData.districtId = parseInt(formData.districtId);
          formData.bankId = parseInt(formData.bankId);
          formData.businessDay = formData.businessDay.map((item) => parseInt(item));
          if (formData.breakTime) {
            formData.breakStartTime = formData.breakTime[0];
            formData.breakEndTime = formData.breakTime[1];
            delete formData.breakTime;
          }
          delete formData.storeBusinessTime;

          // if (!formData.passWord) {
          //   delete formData.passWord;
          // }
          updateStore({ data: formData })
            .then(() => {
              this.$notify.success({ title: '成功', message: '设置成功', duration: 2000 });
              this.$router.replace({ name: 'storeList' });
              this.submitLoadingStatus = false;
            })
            .catch(() => {
              this.submitLoadingStatus = false;
            });
        }
      });
    },
    handleComeBack() {
      this.$router.replace({ name: 'storeList' });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-store-container {
  background-color: #fff;
  .form-container {
    padding: 16px;
    .title {
      padding: 12px 0;
      margin-bottom: 24px;
      border-bottom: 1px solid #ededed;
    }
    .prompt {
      margin-left: 12px;
      color: #e86247;
    }
    .form-submit-container {
      margin-top: 40px;
      text-align: left;
      .form-submit-btn {
        width: 100px;
        margin-right: 20px;
      }
    }
    .select-cordinate {
      height: 32px;
      line-height: 32px;
      padding: 0 15px;
      border: 1px solid #dcdfe6;
      color: #606266;
      border-radius: 4px;
    }
    .select-cordinate:hover {
      cursor: pointer;
    }
    .select-checked {
      color: #606266;
    }
    .select-cordinate-placeholder {
      color: #cfcbcb;
    }
  }
}
.title-subtitle {
  color: #ff0000;
  font-size: 12px;
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}
::v-deep .el-autocomplete-suggestion li {
  line-height: 1.5 !important;
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}
</style>
